const Dashboard = () => {
  return (
    <div className="page_main_content">
      <div className="page_inner_content">
        <h1>Dashboard coming soon</h1>
      </div>
    </div>
  );
};

export default Dashboard;
